
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppCommonMatchCategory} from "@/models/app/common/match-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersProfileNavigationVue from "@/components/administrator/players/profile/navigation.vue";
import AdministratorPlayersProfileMatchesFilterIndexVue from "@/views/app/administrator/players/profile/matches/filter/index.vue";
import AdministratorPlayersProfileMatchesListIndexVue from "@/views/app/administrator/players/profile/matches/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersProfileNavigationVue,
    AdministratorPlayersProfileMatchesFilterIndexVue,
    AdministratorPlayersProfileMatchesListIndexVue
  },
})
export default class AdministratorPlayersProfileMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;

  matchCategories: Array<AppCommonMatchCategory> = [];

  $refs!: {
    AdministratorPlayersProfileMatchesListIndexVue: any;
  };

  getMatchesByFilter(formData: any): void {
    this.$refs.AdministratorPlayersProfileMatchesListIndexVue.getMatches(formData);
  }

  async getMatchCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/match-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matchCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getMatchCategories();
  }
}
